<ion-content>
  <ion-searchbar
    class="search"
    mode="ios"
    placeholder="Search..."
    showCancelButton="never"
    showClearButton="never"
    [(ngModel)]="searchText"
    (ionChange)="handleFilters()"
    (keyup.arrowup)="highlightItemAbove()"
    (keyup.arrowdown)="highlightItemBelow()"
    (keyup.enter)="selectHighlightedItem()"
    [hidden]="!showSearch"
    #searchInput
  ></ion-searchbar>
  <ion-list class="transparent-bg" style="padding: 0 0 5px 0" lines="none" [ngClass]="getClassName()">
    <ion-item
      *ngFor="let item of items; let i = index"
      (click)="selectItem(item)"
      button="true"
      class="ion-item-rounded"
      [ngClass]="{ highlighted: i === highlightedItemIndex }"
    >
      <app-avatar
        *ngIf="showImages"
        size="22"
        [style.height]="'22px'"
        [style.width]="'22px'"
        [style.margin]="'0 5px 0 -5px'"
        slot="start"
        [name]="item.title"
        [image]="item.image"
      >
      </app-avatar>
      <ion-label>
        {{ item.title }}
      </ion-label>
      <ion-icon slot="end" name="checkmark" *ngIf="item.id === selectedItemId" style="margin: 0"></ion-icon>
    </ion-item>
    <ion-item
      *ngIf="isLoading === false && !items?.length && dynamicCreationCallback && searchText?.length"
      (click)="createNewItem()"
      button="true"
    >
      <ion-icon name="add-outline" style="font-size: 0.9rem; margin-right: 7px"></ion-icon>
      Create "{{ searchText }}"
    </ion-item>
    <ion-item
      *ngIf="isLoading === false && !items?.length && !dynamicCreationCallback"
      [routerLink]="placeholderLink"
      routerDirection="root"
      (click)="dismiss()"
    >
      {{ placeholderLink ? 'None found. Click to add' : 'No results' }}
    </ion-item>
  </ion-list>
  <div
    *ngIf="isLoading"
    style="display: flex; justify-content: center; align-items: center; opacity: 0.5; height: 100%; margin: 10px"
  >
    <ion-spinner name="crescent"></ion-spinner>
  </div>
</ion-content>
