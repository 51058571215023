import { Injectable } from '@angular/core';

import { SlackUserGroup } from 'src/models';

import { GenericObjectsService } from './generic-objects.service';

@Injectable({
  providedIn: 'root',
})
export class SlackUserGroupsService extends GenericObjectsService<SlackUserGroup> {
  protected baseUrl = '/slack-user-groups';
}
