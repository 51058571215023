import { Injectable } from '@angular/core';

import { Automation, AutomationRun, ListRequest, ListResponse } from 'src/models';

import { GenericObjectsService } from './generic-objects.service';

@Injectable({
  providedIn: 'root',
})
export class AutomationsService extends GenericObjectsService<Automation> {
  protected baseUrl = '/automations';

  listRunsForAutomation(
    automationId: string,
    options: ListRequest = {},
  ): Promise<ListResponse<Partial<AutomationRun>>> {
    return this.apiService.postPromise(`${this.baseUrl}/${automationId}/runs/list`, options);
  }

  listRunsForConversation(
    conversationId: string,
    options: ListRequest = {},
  ): Promise<ListResponse<Partial<AutomationRun>>> {
    return this.apiService.postPromise(`${this.baseUrl}/conversations/${conversationId}/runs/list`, options);
  }

  runAutomationForConversation(automationId: string, conversationId: string, input: any = {}): Promise<any> {
    return this.apiService.postPromise(`${this.baseUrl}/${automationId}/conversations/${conversationId}/trigger`, {
      input,
    });
  }

  listLogEntriesForRun(automationId: string, automationRunId: string): Promise<any[]> {
    return this.apiService.getPromise(`${this.baseUrl}/${automationId}/runs/${automationRunId}/logs`);
  }

  getSecretNames(): Promise<string[]> {
    return this.apiService.getPromise(`${this.baseUrl}/secrets`);
  }

  deleteSecret(secretName: string): Promise<void> {
    return this.apiService.deletePromise(`${this.baseUrl}/secrets/${secretName}`);
  }

  addSecret(name: string, value: string): Promise<void> {
    return this.apiService.putPromise(`${this.baseUrl}/secrets`, {
      name,
      value,
    });
  }

  getAllFunctionsVersions() {
    return this.apiService.getPromise(`${this.baseUrl}/functions`);
  }

  getFunctions(version: string | number) {
    return this.apiService.getPromise(`${this.baseUrl}/functions/${version}`);
  }

  getStores() {
    return this.apiService.getPromise(`${this.baseUrl}/stores`);
  }

  addStore({ name, description }: { name: string; description: string }) {
    return this.apiService.postPromise(`${this.baseUrl}/stores`, {
      name,
      description,
    });
  }

  deleteStore(storeName: string) {
    return this.apiService.deletePromise(`${this.baseUrl}/stores/${storeName}`);
  }
}
