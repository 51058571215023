import { Injectable } from '@angular/core';

import { TicketType } from 'src/models';

import { GenericObjectsService } from './generic-objects.service';

@Injectable({
  providedIn: 'root',
})
export class TicketTypesService extends GenericObjectsService<TicketType> {
  protected baseUrl = '/ticket-types';
}
